@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

html {
  line-height: 1.4;
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  background-color: #01030B;
  color: #b2acaf;
}
a {
  text-decoration: none;
  color: #b2acaf;
}
header {
  padding: 30px 0 2px;
  text-align: center;
}
.app-logo {
  display: inline-block;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
  text-shadow: 0px 1px 0px #bdc3c7;
  color: #2c3e50;
  span {
    font-weight: normal;
    text-shadow: none;
  }
}
.main-nav {
  padding: 15px 0 15px;
  background-color: #fff;
  border-top: 1px solid #f5f5f5;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, .2);
  .text-link {
    text-transform: capitalize;
  }
}
.text-link {
  position: relative;
  margin: 0 10px;
  padding: 0 2px;
  &:before {
    content: '';
    position: absolute;
    right: 50%;
    left: 50%;
    bottom: -5px;
    width: 0;
    height: 1px;
    background-color: #2980b9;
    transition: all 300ms;
  }
  &:hover:before,
  &.-active:before {
    right: 0;
    left: 0;
    width: 100%;
  }
}
.main-slider {
  padding-bottom: 35%;
  background-color: #f9f9f9;
  border-bottom: 1px solid #eee;
}
.queue-slider {
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
\\  common style
* {
  outline: none;
}
ul {
  padding: 0;
}
li {
  list-style: none;
}
.text-center {
  text-align: center;
}
figure {
  margin: 0;
}
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: inline-block;
  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
}
.clearfix {
  display: block;
}
\\  grid system
.page-container {
  margin: 0 auto;
  max-width: 80%;
}
.row {
 display: block;
 margin: 0 -15px;
}
.grid-12,
.grid-9,
.grid-6,
.grid-4,
.grid-3 {
  float: left;
  padding: 0 15px;
}
.grid-12 {
  width: 100%;
}
.grid-9 {
  width: 75%;
}
.grid-6 {
  width: 50%;
}
.grid-4 {
  width: 33.33%;
}
.grid-3 {
  width: 25%;
}
.img[src="#"] {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border: 1px solid #f5f5f5;
  &:after {
    content: attr(alt);
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #fff;
    color: #999;
  }
}
.feature-item {
  display: inline-block;
  margin-right: -4px;
  float: inherit;
  .img[src="#"] {
    width: 80px;
    height: 80px;
    line-height: 80px;
    padding-bottom: inherit;
  }
}
.feature-item,
.portfolio-item {
  vertical-align: top;
}
.feature-item,
.article-item {
  padding-bottom: 30px;
}
.portfolio-item {
  padding-top: 30px;
  padding-bottom: 30px;
}
.title {
  margin-bottom: 14px;
  font-size: 18px;
}
.desc {
  margin: 10px 0;
}
.title.-section {
  margin-top: 14px;
}
.blog-container {
  .img[src="#"] {
    height: 150px;
    line-height: 150px;
    padding-bottom: inherit;
  }
  .title {
    margin-top: 14px;
  }
}
footer {
  padding: 20px 0;
  background-color: #f5f5f5;
  color: #333;
  .title {
    font-weight: normal;
  }
}
.foo-nav {
  li {
    margin: 5px 0;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}
input,
textarea {
  width: 100%;
  margin: 10px 0;
  padding: 8px 10px;
  font-size: 14px;
  border-radius: 3px;
  border: 0;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
}
textarea {
  height: 150px;
  resize: none;
}
.btn {
  padding: 10px 15px;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  border-radius: 3px;
  border: 0;
  background-color: #fff;
  cursor: pointer;
  transition: box-shadow 300ms;
  &:hover {
    box-shadow: none;
  }
}
.m-0 {
  margin: 0;
}
.p-l-0 {
  padding-left: 0;
}
.p-x-0 {
  padding-right: 0;
  padding-left: 0;
}
